import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Logo from '../assets/img/insurancegig_logo.png';
import Logotext from '../assets/img/igiglogo.png';
import CommonValues from './Util';

class Sidebar extends Component {
    onAdminclicked = (e) => {
        this.props.history.push("/igigadmin")
        e.preventDefault()
    }

    onUserMasterclicked = (e) => {
        this.props.history.push("/userlist")
        e.preventDefault()
    }

    onRTGUserMasterclicked = (e) => {
        this.props.history.push("/userlist")
        e.preventDefault()
    }

    onWorkflowMasterclicked = (e) => {
        this.props.history.push("/workflowlist")
        e.preventDefault()
    }
    onDataFabricClicked = (e) => {
        this.props.history.push("/datafabric")
        e.preventDefault()
    }
    onGIGWorkflowClicked = (e) => {
        this.props.history.push("/gigworkflow")
        e.preventDefault()
    }
    onCompanyMasterclicked = (e) => {
        this.props.history.push("/companylist")
        e.preventDefault()
    }
    onServiceMasterclicked = (e) => {
        this.props.history.push("/servicelist")
        e.preventDefault()
    }
    onReportclicked = (e) => {
        this.props.history.push("/report")
        e.preventDefault()
    }
    onPartnerReportclicked = (e) => {
        this.props.history.push("/partnerreport")
        e.preventDefault()
    }
    onWorkflowDashboardclicked = (e) => {
        this.props.history.push("/workflowdashboard")
        e.preventDefault()
    }
    onCompanyclicked = (e) => {
        this.props.history.push("/company")
        e.preventDefault()
    }
    onEventclicked = (e) => {
        this.props.history.push("/eventlist")
        e.preventDefault()
    }
    onBlogclicked = (e) => {
        this.props.history.push("/bloglist")
        e.preventDefault()
    }
    onLogoutclicked = (e) => {
        this.props.history.push("/")
        CommonValues.ClearValues();
        e.preventDefault()
    }
    onGigCodeMasterClicked = (e) => {
        this.props.history.push("/gigcodemaster")
        e.preventDefault()
    }
    render() {
        return (
            <aside className="main-sidebar sidebar-primary elevation-4">

                <a href="/companylist" className="brand-link">
                    <img
                        src={Logo}
                        alt="Insurancegig"
                        className="brand-image"

                    />

                    <img
                        src={Logotext}
                        alt="Insurancegig"
                        className="brand-text font-weight-light"
                        style={{ width: '100px' }}

                    />
                </a>

                <div className="sidebar">
                    <div className="form-inline">
                    </div>
                    <nav className="mt-2">
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false"
                        >
                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onCompanyMasterclicked(e)} className="nav-link">
                                            <i className="fa fa-building" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>Company Master</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onServiceMasterclicked(e)} className="nav-link">
                                            <i className="fa fa-cog" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>Service Master</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>


                            {/* <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onUserMasterclicked(e)} className="nav-link">
                                            <i className="fa fa-user" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>User Master - Old </p>
                                        </a>
                                    </li>
                                </ul>
                            </li> */}


                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onRTGUserMasterclicked(e)} className="nav-link">
                                            <i className="fa fa-user" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>User Master </p>
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onWorkflowMasterclicked(e)} className="nav-link">
                                            <i className="fa fa-sitemap" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>Workflow Master </p>
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onDataFabricClicked(e)} className="nav-link">
                                            <i className="fa fa-info" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>Data Fabric Master</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            {/* 
                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onGIGWorkflowClicked(e)} className="nav-link">
                                            <i className="fa fa-info" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>GIG Flow Master</p>
                                        </a>
                                    </li>
                                </ul>
                            </li> */}


                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onReportclicked(e)} className="nav-link">
                                            <i className="fa fa-file" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>Service Report </p>
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onPartnerReportclicked(e)} className="nav-link">
                                            <i className="fa fa-file" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p> All Partners Usage Report</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onEventclicked(e)} className="nav-link">
                                            <i className="fa fa-file" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>Event Master </p>
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer" }} onClick={(e) => this.onBlogclicked(e)} className="nav-link">
                                            <i className="fa fa-file" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>Blog Master</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>


                            <li className="nav-item menu-open">
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a style={{ cursor: "pointer"  }} onClick={(e) => this.onGigCodeMasterClicked(e)}  className="nav-link">
                                            <i className="fa fa-building" aria-hidden="true" style={{ paddingRight: "5px" }}></i>
                                            <p>GigCode Master</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </nav>
                </div>
            </aside>
        )
    }
}
export default withRouter(Sidebar);
